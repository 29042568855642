import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../stores'
import { useDispatch } from 'react-redux'
import { loadClientDetail } from '../../../stores/clients/actions'
import { Pagination, Table } from '../../../components'
import { useParams } from 'react-router-dom'
import { clientService } from '../../../services'
import { df2dfd, dfd2df } from '../../../helpers'
import $ from 'jquery'
import { Link } from 'react-router-dom'


export const Management = () => {
    const filterColumns = ["idClient", "depositRatio", "nav", "totalValueSell"]
    const sortColumns = ["idClient", "nav", "totalValueSell"]
    let { brokerName } = useParams()
    const [managementRes, setManagementRes] = useState<Record<any, any>>({})
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 50

    const [filterBy, setFilterBy] = useState<any>({})
    const [filterByDelay, setFilterByDelay] = useState<any>({})
    const [sortByState, setSortByState] = useState<any>({ nav: "DESC", totalValueSell: "", idClient: "" })
    // const [sortByDelay, setSortByDelay] = useState<any>({nav: "desc", totalValueSell: ""})
    const [sortElements, setSortElements] = useState<any>([])
    const getFilters = () => {
        const filterBy = {} as any
        for (const col of filterColumns) {
            const value = $(`#${col}Input`).val() as string;
            filterBy[col] = value === "" ? undefined : value
        }
        return filterBy
    }

    const onChangeFilter = (e: any) => {
        setFilterBy(getFilters())
    }

    const onChangeOrder = (fieldName: string) => {
        const func = () => {
            let value = sortByState[fieldName]
            if (value === "") {
                value = "DESC"
            } else if (value === "DESC") {
                value = "ASC"
            } else {
                value = ""
            }
            setSortByState({ ...sortByState, [fieldName]: value })
        }
        return func
    }

    const loadMangement = async (_brokerName: string, _page: number, _pageSize: number, filterBy: any, sortBy: any) => {
        console.log(filterBy)
        await clientService.loadMangement(_brokerName, _page - 1, _pageSize, filterBy, sortBy).then((res) => {
            var dfd = df2dfd(res.data.data)
            dfd = dfd.generateSeries({
                depositRatio: (row) => <span style={{ color: (row.depositRatio >= 0.2 ? "green" : "red") }}>{(row.depositRatio * 100).toLocaleString() + "%"}</span>
            })
            // dfd = dfd.generateSeries({
            //     updatedAt: (row) => <span className="text-nowrap">{row.updatedAt}</span>,
            //     nameClient: (row) => <span className="text-nowrap">{row.nameClient}</span>,
            // })
            const map = {
                idClient: "Tài khoản",
                nameClient: "Tên",
                deposit: "Tiền nộp cọc",
                nav: "NAV hiện tại",
                depositRatio: "Tỷ lệ đảm bảo",
                pnl	: "Lãi/Lỗ",
                totalValueCountsForDeposit: "Tồng tiền tính cọc",
                totalValueBuy: "Tổng tiền mua",
                totalValueSell: "Tổng tiền bán",
                __updatedAt__: "Ngày/Giờ cập nhật",
            } as any
            const roundCols = {} as any
            Object.keys(map).filter((key: any) => {
                if(!["idClient", "nameClient", "depositRatio", "__updatedAt__"].includes(key)){
                    roundCols[key] = (row: any) => Number(row[key]) === row[key] ? Math.round(row[key]) : row[key]
                }
            })
            dfd = dfd.generateSeries(roundCols)
            dfd = dfd.reorderSeries(Object.keys(map))
            const filterRows = []
            const sortElements = []
            for (const col of dfd.getColumnNames()) {
                if (sortColumns.includes(col)) {
                    sortElements.push({ field: col, state: sortByState, onClick: onChangeOrder(col) })
                } else {
                    sortElements.push(undefined)
                }
                if (filterColumns.includes(col)) {
                    const placeholder = ["depositRatio", "nav", "totalValueSell"].includes(col) ? undefined : "search"
                    const defaultValue = col === "depositRatio" ? "!= 0" : undefined
                    filterRows.push(<input type="text" className="form-control form-control-sm" placeholder={placeholder} defaultValue={defaultValue} onChange={onChangeFilter} id={`${col}Input`} />)
                } else { filterRows.push(undefined) }
            }
            setSortElements(sortElements)
            dfd = dfd.generateSeries({
                idClient: (row) => row.idClient !== "Tổng" ? <Link className='text-dark' to={`/clients/${row.idClient}`}>{row.idClient}</Link> : row.idClient
            })
            dfd = dfd.renameSeries(map)
            // <Link className='text-dark' to={`/admin/users/${user.id}`}>{user.email}</Link>
            res.data.data = dfd2df(dfd)
            res.data.data.records = [filterRows].concat(res.data.data.records)
            setManagementRes(res)
            setTotal(res.data.total)
        }, (error) => { });
    }

    const onPageChanged = (page: number) => {
        setCurrentPage(page)
    }
    useEffect(() => {
        if (brokerName) {
            loadMangement(
                brokerName, currentPage, pageSize, getFilters(), Object.keys(sortByState).filter((key: any)=>(sortByState[key]!=="")).map((key: any) => ({ field: key, direction: sortByState[key] }))
            )
        }
        const intervalId = setInterval(() => {
            if (brokerName) loadMangement(
                brokerName, currentPage, pageSize, getFilters(), Object.keys(sortByState).filter((key: any)=>(sortByState[key]!=="")).map((key: any) => ({ field: key, direction: sortByState[key] }))
            )
        }, brokerName ? 1000 * 5 : 0)
        return () => clearInterval(intervalId)
    }, [brokerName, filterByDelay, currentPage, sortByState])

    useEffect(() => {
        const timeOutId = setTimeout(() => { setFilterByDelay(filterBy) }, 1000);
        return () => clearTimeout(timeOutId);
    }, [filterBy])

    return (
        < div className="container-fluid" >
            {/* Page Heading */}
            < h1 className="h3 mb-4 text-dark" >{`Báo cáo Quản trị: ${brokerName}`}</h1 >
            <div className="row">
                <div className="col-lg">
                    <h1 className="h4 mb-2 text-black-50"></h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            {managementRes.data?.data ? <Table data={managementRes.data.data} hightLightLastRow={true} firstSticky={true} sortElements={sortElements}></Table> : <></>}
                        </div>
                        <div className='card-footer'>
                            <Pagination total={total} currentPage={currentPage} pageSize={pageSize} pageLimit={5} onPageChanged={onPageChanged} />
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

