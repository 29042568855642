import { DataFrame } from '../helpers'


export const Table = (props: { data?: DataFrame, hightLightLastRow: boolean, firstSticky: boolean, sortElements: Array<any> }) => {
    if (!props.data) return <></>
    const data = props.data.records
    const splitIndex = props.hightLightLastRow ? -1 : data.length
    const format = (r: any) => { r = (Math.round(r * 100) / 100).toLocaleString(); return r }
    const elements = data.slice(0, splitIndex).map((record, index) => {
        return (
            <tr>
                {
                    record.map((r) => <td>{
                        <span className="text-nowrap">{Number(r) === r ? format(r) : (r ? r: "")}</span>
                    }</td>)}

            </tr>
        )
    })
    const footer = data.slice(splitIndex).map((record) => {
        return (
            <tr>
                {record.map((r) => <th>{
                    <span className="text-nowrap">{Number(r) === r ? format(r) : (r ? r: "")}</span>
                }</th>)}

            </tr>
        )
    })
    return (
        <div className="table-responsive">
            <table className="table table-bordered table-sm th tbale-sm td dataTable" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                    <tr>
                        {props.data.schema.map((s, index) => {
                            const element = props.sortElements[index]
                            let direction = element ? element.state[element.field] : undefined
                            direction = direction === "" ? "sorting" : (direction === "ASC" ? "sorting sorting_asc" : "sorting sorting_desc")
                            return <th className={element ? direction : undefined} onClick={element ? element.onClick : undefined}><span className='text-nowrap'>{s}</span></th>
                        })}
                    </tr>
                </thead>
                <tbody>{elements}{footer}</tbody>
            </table>
        </div>

    )
}