import React from 'react'
import { LeftMenu } from '../LeftMenu/LeftMenu'
import { TopBar } from '../TopBar/TopBar'
import { Route, Routes } from 'react-router'
import { Home } from './Home/Home'
import { ClientPagination, ClientDetail, Portfolio, Management } from './Clients'
import { CreateUser, ListUser, UserDetail, UserEdit } from './Users'
import { ResetPassword, ResetPasswordSuccess } from '../Account'

export const Admin = () => {
    return (
        <>
            <LeftMenu />
            {/* Content Wrapper */}
            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    <TopBar />
                    {/* Begin Page Content */}
                    <div className="container-fluid">
                        <Routes>
                            <Route path='/' element = {<ClientPagination/>}></Route>
                            <Route path='/clients' element = {<ClientPagination/>}></Route>
                            <Route path='/management/management/:brokerName' element = {<Management/>}></Route>
                            <Route path='/management/portfolio/:brokerName' element = {<Portfolio/>}></Route>
                            <Route path='/clients/:id' element = {<ClientDetail/>}></Route>
                            <Route path='/admin/users' element = {<ListUser/>}></Route>
                            <Route path='/admin/users/create' element = {<CreateUser/>}></Route>
                            <Route path='/admin/users/:userID' element = {<UserDetail/>}></Route>
                            <Route path='/admin/users/:userID/edit' element = {<UserEdit/>}></Route>
                            <Route path='/resetPassword' element = {<ResetPassword/>}></Route>
                            <Route path='/resetPasswordSuccess' element = {<ResetPasswordSuccess/>}></Route>
                        </Routes>
                    </div>
                    {/* /.container-fluid */}
                </div>
            </div>
            {/* End of Content Wrapper */}
        </>
    )
}
